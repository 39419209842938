
import { defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGettext } from "vue3-gettext";

import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { timestampConverter, processAmount } from "@/core/helpers/utils";
import { Actions } from "@/store/enums/StoreEnums";
//import { paths } from "@/gen_invoices_schema";
import * as bootstrap from "bootstrap";

interface CurrentData {
  lastInvoice: number;
  datetime: object;
  amountRaw: number;
  amount: number | string;
  currency: string;
}

export default defineComponent({
  name: "my-orders",
  components: {},
  data() {
    const store = useStore();
    const router = useRouter();
    const gettext = useGettext();

    return {
      gettext,
      router,
      store,
      items: [] as CurrentData[],
      activeRow: null,
      currentData: null as CurrentData | null,
    };
  },
  async created() {
    this.updateRunningTotal();
    this.updateItems();

    watch(
      () => this.gettext.current,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          if (this.currentData?.amountRaw != null) {
            console.log(this.currentData);
            processAmount(
              "amount",
              this.currentData,
              this.store.state.CurrencyModule.currencies,
              this.currentData.currency,
              this.gettext.current
            );
          }
          for (const row of this.items) {
            if (row.amountRaw != null) {
              processAmount(
                "amount",
                row,
                this.store.state.CurrencyModule.currencies,
                row.currency,
                this.gettext.current
              );
            }
          }
        }
      }
    );
  },
  methods: {
    updateItems() {
      this.store.dispatch(Actions.INVOICES).then(({ data }) => {
        for (const row of data) {
          processAmount(
            "amount",
            row,
            this.store.state.CurrencyModule.currencies,
            row.currency,
            this.gettext.current
          );
        }
        this.items = data;
      });
    },
    updateRunningTotal() {
      this.store.dispatch(Actions.RUNNING_TOTAL).then(({ data }) => {
        data.datetime = timestampConverter(data["lastInvoice"]);
        processAmount(
          "amount",
          data,
          this.store.state.CurrencyModule.currencies,
          data.currency,
          this.gettext.current
        );
        this.currentData = data;
      });
    },
    navigateToInvoice(id) {
      this.router.push(`/invoice/${id}`);
    },
    newInvoice() {
      this.store.dispatch(Actions.NEW_INVOICE).then(() => {
        this.$nextTick(() => {
          const myModalEl = this.$refs.invoiceModal;
          const modal = bootstrap.Modal.getInstance(myModalEl);
          if (modal) {
            this.updateRunningTotal();
            this.updateItems();
            modal.hide();
          }
        });
      });
    },
  },

  setup() {
    const store = useStore();
    const token = getToken(ID_TOKEN_KEY);
    store.dispatch(Actions.LOGIN_TOKEN_CHANGED, token);
    onMounted(() => {
      setCurrentPageTitle("Invoices");
    });
  },
});
