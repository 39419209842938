<template>
  <!--begin::MyOrders-->
  <div class="row gy-5 gx-xl-8">
    <div class="col-xxl-12">
      <div class="card card-xxl-stretch mb-5 mb-xl-8">
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $gettext("Current overview")
            }}</span>
          </h3>
          <div
            class="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            :title="$gettext('Click to generate invoice')"
          >
            <span
              class="info-icon pr-10"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-trigger="hover"
              v-if="currentData == null || currentData.amountRaw <= 0"
              :title="
                $gettext(
                  'Negative invoice will be automatically processed each month'
                )
              "
            >
              <inline-svg
                src="media/icons/duotone/Code/Info-circle-primary.svg"
              />
            </span>
            <a
              :class="
                currentData == null || currentData.amountRaw <= 0
                  ? 'disabled'
                  : ''
              "
              class="btn btn-sm btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_choose_payment"
            >
              <span class="svg-icon svg-icon-3">
                <inline-svg src="media/icons/duotone/Files/File-Plus.svg" />
              </span>
              {{ $gettext("New invoice") }}
            </a>
          </div>
        </div>
        <div class="card-body py-3">
          <div class="row gy-5 gx-xl-8">
            <!-- First Half -->
            <div class="col-sm-4">
              <div v-if="currentData != null">
                {{ $gettext("Amount:") }}
                {{ currentData.amount }}
              </div>
            </div>

            <!-- Second Half -->
            <div class="col-sm-4">
              <div
                v-if="currentData != null && currentData.lastInvoice != null"
              >
                {{ $gettext("Last invoice:") }}
                {{
                  new Intl.DateTimeFormat(gettext.current, {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }).format(new Date(currentData.lastInvoice))
                }}
                ({{ currentData.datetime.days }} {{ $gettext("Days Ago") }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <div class="card card-xxl-stretch mb-5 mb-xl-8">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">{{
              $gettext("Invoices")
            }}</span>
            <span class="text-muted mt-1 fw-bold fs-7">{{
              $gettext("List of invoices")
            }}</span>
          </h3>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body py-3">
          <div class="tab-content">
            <!--begin::Tap pane-->
            <div>
              <!--begin::Table container-->
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table align-middle gs-0 gy-3">
                  <!--begin::Table head-->
                  <template v-if="items.length < 1">
                    <strong class="ms-15"
                      ><i>{{
                        $gettext("There are no invoices to show yet")
                      }}</i></strong
                    >
                  </template>
                  <template v-if="items.length > 0">
                    <thead>
                      <tr>
                        <!--<th class="p-0 w-50px"></th>
                    <th class="p-0 min-w-150px"></th>
                    <th class="p-0 min-w-140px"></th>
                    <th class="p-0 min-w-120px"></th>-->
                        <th class="p-0 px-5">
                          {{ $gettext("Invoice number") }}
                        </th>
                        <th class="p-0 px-5">{{ $gettext("Date") }}</th>
                        <th class="p-0 px-5">{{ $gettext("From") }}</th>
                        <th class="p-0 px-5">{{ $gettext("To") }}</th>
                        <th class="p-0 px-5">{{ $gettext("Amount") }}</th>
                        <th class="p-0 px-5">{{ $gettext("Status") }}</th>
                      </tr>
                    </thead>
                    <!--end::Table head-->

                    <!--begin::Table body-->
                    <tbody>
                      <template v-for="(item, index) in items" :key="index">
                        <tr
                          @click="navigateToInvoice(item.invoiceNum)"
                          class="cursor-pointer"
                          @mouseover="activeRow = item.invoiceNum"
                          @mouseleave="activeRow = null"
                          :class="{
                            'table-active': activeRow === item.invoiceNum,
                          }"
                        >
                          <td>
                            <span class="text-dark fw-bolder d-block fs-5 ms-5">
                              {{ item.invoiceNum }}
                            </span>
                          </td>
                          <td class="text-dark fw-bolder fs-5 mx-2">
                            {{
                              new Intl.DateTimeFormat(gettext.current).format(
                                item.date
                              )
                            }}
                          </td>
                          <td class="text-dark fw-bolder fs-5 mx-2">
                            {{
                              new Intl.DateTimeFormat(gettext.current).format(
                                item.from
                              )
                            }}
                          </td>
                          <td class="text-dark fw-bolder fs-5 mx-2">
                            {{
                              new Intl.DateTimeFormat(gettext.current).format(
                                item.to
                              )
                            }}
                          </td>
                          <!--<td>
                          <span
                            class="text-muted fw-bolder fs-5 text-center d-inline-block"
                            >{{ item.datetime.from.time }}
                          </span>
                          <span
                            class="text-dark fw-bolder fs-5 text-center d-inline-block mx-2"
                            >{{ item.datetime.from.date }}</span
                          >
                        </td>
                        <td>
                          <span
                            class="text-muted fw-bolder fs-5 text-center d-inline-block"
                            >{{ item.datetime.to.time }}
                          </span>
                          <span
                            class="text-dark fw-bolder fs-5 text-center d-inline-block mx-2"
                            >{{ item.datetime.to.date }}</span
                          >
                        </td>
                        -->
                          <td>
                            <span class="text-dark fw-bolder d-block fs-5"
                              >{{ item.amount }}
                            </span>
                          </td>
                          <td>
                            <span
                              class="badge text-center"
                              :class="{
                                'badge-light-danger': item.status == 1,
                                'badge-light-success':
                                  item.status != 0 && item.status != 1,
                              }"
                            >
                              {{
                                item.status == 1
                                  ? $gettext("Unpaid")
                                  : $gettext("Paid")
                              }}</span
                            >
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                  <!--end::Table body-->
                </table>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Tap pane-->
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Tables Widget 6-->
      <div
        class="modal fade"
        id="kt_modal_choose_payment"
        tabindex="-1"
        aria-hidden="true"
        ref="invoiceModal"
      >
        <div class="modal-dialog mw-650px">
          <!--begin::Modal content-->
          <div class="modal-content">
            <!--begin::Modal header-->

            <div class="modal-header pb-0 border-0">
              <h2>Choose invoice generate type</h2>
              <!--begin::Close-->
              <div
                class="btn btn-sm btn-icon btn-active-color-primary justify-content-end"
                data-bs-dismiss="modal"
              >
                <span class="svg-icon svg-icon-1">
                  <inline-svg src="media/icons/duotone/Navigation/Close.svg" />
                </span>
              </div>
              <!--end::Close-->
            </div>
            <!--begin::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body scroll-y mx-5 mx-xl-18 pt-5">
              <p>
                {{
                  $gettext(
                    'If you choose option "own payment" you have to provide an invoice from your own accounting service.'
                  )
                }}
              </p>
            </div>
            <div class="container-fluid pb-10">
              <div class="row justify-content-center text-center">
                <div class="col-5">
                  <a class="btn btn-primary btn-block" @click="newInvoice()">
                    {{ $gettext("Automatic Payment") }}
                  </a>
                </div>
                <div class="col-5">
                  <a
                    class="btn btn-secondary btn-block"
                    href="mailto:support@printeepro.com?subject=Own%20payment%20for%20invoice%20{invoice number}&body=Hello%20Support, I am attaching my invoice below."
                  >
                    {{ $gettext("Own Payment") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::MyOrders-->
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGettext } from "vue3-gettext";

import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { timestampConverter, processAmount } from "@/core/helpers/utils";
import { Actions } from "@/store/enums/StoreEnums";
//import { paths } from "@/gen_invoices_schema";
import * as bootstrap from "bootstrap";

interface CurrentData {
  lastInvoice: number;
  datetime: object;
  amountRaw: number;
  amount: number | string;
  currency: string;
}

export default defineComponent({
  name: "my-orders",
  components: {},
  data() {
    const store = useStore();
    const router = useRouter();
    const gettext = useGettext();

    return {
      gettext,
      router,
      store,
      items: [] as CurrentData[],
      activeRow: null,
      currentData: null as CurrentData | null,
    };
  },
  async created() {
    this.updateRunningTotal();
    this.updateItems();

    watch(
      () => this.gettext.current,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          if (this.currentData?.amountRaw != null) {
            console.log(this.currentData);
            processAmount(
              "amount",
              this.currentData,
              this.store.state.CurrencyModule.currencies,
              this.currentData.currency,
              this.gettext.current
            );
          }
          for (const row of this.items) {
            if (row.amountRaw != null) {
              processAmount(
                "amount",
                row,
                this.store.state.CurrencyModule.currencies,
                row.currency,
                this.gettext.current
              );
            }
          }
        }
      }
    );
  },
  methods: {
    updateItems() {
      this.store.dispatch(Actions.INVOICES).then(({ data }) => {
        for (const row of data) {
          processAmount(
            "amount",
            row,
            this.store.state.CurrencyModule.currencies,
            row.currency,
            this.gettext.current
          );
        }
        this.items = data;
      });
    },
    updateRunningTotal() {
      this.store.dispatch(Actions.RUNNING_TOTAL).then(({ data }) => {
        data.datetime = timestampConverter(data["lastInvoice"]);
        processAmount(
          "amount",
          data,
          this.store.state.CurrencyModule.currencies,
          data.currency,
          this.gettext.current
        );
        this.currentData = data;
      });
    },
    navigateToInvoice(id) {
      this.router.push(`/invoice/${id}`);
    },
    newInvoice() {
      this.store.dispatch(Actions.NEW_INVOICE).then(() => {
        this.$nextTick(() => {
          const myModalEl = this.$refs.invoiceModal;
          const modal = bootstrap.Modal.getInstance(myModalEl);
          if (modal) {
            this.updateRunningTotal();
            this.updateItems();
            modal.hide();
          }
        });
      });
    },
  },

  setup() {
    const store = useStore();
    const token = getToken(ID_TOKEN_KEY);
    store.dispatch(Actions.LOGIN_TOKEN_CHANGED, token);
    onMounted(() => {
      setCurrentPageTitle("Invoices");
    });
  },
});
</script>
